import {Resources} from "../../../../../../../resources/Resources";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "../statistics/website-content-statistics.scss";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteColumnsDoubleComponent} from "../../columns/double/WebsiteColumnsDoubleComponent";
export class WebsiteContentBlogStyleComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentStatistics}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.writingStyleReplication"),
                            description: Resources.t("words.styleCloningFeatureDescription"),
                            image: NetworkVendor.image("blog-h.webp"),
                            color: "black",
                            size: "tall",
                            border: "border",
                            shadow: "shadow"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsDoubleComponent({
                            color: "black",
                            title: Resources.t("words.whyAdoptStyleCloning"),
                            columns: [{
                                icon: "handshake",
                                name: Resources.t("words.brandConsistency"),
                                text: Resources.t("words.brandConsistencyDescription"),
                            }, {
                                icon: "seo",
                                name: Resources.t("words.seoOptimization"),
                                text: Resources.t("words.seoOptimizationDescription")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                                    "questions": [
                                        {
                                            "question": Resources.t("words.howDoesStyleCloningWork"),
                                            "response": Resources.t("words.styleCloningWorkDescription")
                                        },
                                        {
                                            "question": Resources.t("words.styleCloningAccuracy"),
                                            "response": Resources.t("words.styleCloningAccuracyDescription")
                                        },
                                        {
                                            "question": Resources.t("words.canEditGeneratedArticles"),
                                            "response": Resources.t("words.canEditGeneratedArticlesDescription")
                                        },
                                        {
                                            "question": Resources.t("words.adaptableToDifferentContentTypes"),
                                            "response": Resources.t("words.adaptableToDifferentContentTypesDescription")
                                        },
                                        {
                                            "question": Resources.t("words.seoOptimizedArticles"),
                                            "response": Resources.t("words.seoOptimizedArticlesDescription")
                                        },
                                        {
                                            "question": Resources.t("words.styleCloningTime"),
                                            "response": Resources.t("words.styleCloningTimeDescription")
                                        },
                                        {
                                            "question": Resources.t("words.changeWritingStyle"),
                                            "response": Resources.t("words.changeWritingStyleDescription")
                                        },
                                        {
                                            "question": Resources.t("words.adaptableToAllIndustries"),
                                            "response": Resources.t("words.adaptableToAllIndustriesDescription")
                                        },
                                        {
                                            "question": Resources.t("words.multipleAuthors"),
                                            "response": Resources.t("words.multipleAuthorsDescription")
                                        }
                                    ]
                                }
                        ))}
                    </div>
                </div>
            </div>
        `;
    }
}
