import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-solutions.scss";
import {WebsiteSliderFlatComponent} from "../slider/flat/WebsiteSliderFlatComponent";
import {WebsiteSliderCardLiveChatComponent} from "../slider/card/models/WebsiteSliderCardLiveChatComponent";
import {WebsiteSliderCardMailsComponent} from "../slider/card/models/WebsiteSliderCardMailsComponent";
import {WebsiteSliderCardHelpCenterComponent} from "../slider/card/models/WebsiteSliderCardHelpCenterComponent";
import {WebsiteSliderCardInboxComponent} from "../slider/card/models/WebsiteSliderCardInboxComponent";
import {Resources} from "../../../../../../resources/Resources";
import {WebsiteSliderCardContactsComponents} from "../slider/card/models/WebsiteSliderCardContactsComponents";
import {WebsiteSliderCardBlogComponent} from "../slider/card/models/WebsiteSliderCardBlogComponent";

export class WebsiteSolutionsComponent extends Component {

    public contentContainer: Component;

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteSolutions}">
                <div class="${s.head}">
                    <h2 class="${s.title}">
                        ${Resources.t("words.ourSolutions")}
                    </h2>
                    <div class="${s.buttons}">
                        <div class="${s.button} ${s.blog}">
                            ${Resources.t("words.products.blog")}
                        </div>
                            <!--
                        <div  class="${s.button} ${s.inbox}">
                            ${Resources.t("words.products.inbox")}
                        </div>
                        <div class="${s.button} ${s.livechat}">
                            ${Resources.t("words.products.livechat")}
                        </div>
                        <div class="${s.button} ${s.mails}">
                            ${Resources.t("words.products.mails")}
                        </div>
                        <div class="${s.separation}"><p></p></div>
                        <div class="${s.button} ${s.frHelpcenter}">
                            ${Resources.t("words.products.helpCenter")}
                            <p class="${s.plus}">
                                ${Resources.t("words.soon")}
                            </p>
                        </div>
                        <div class="${s.button} ${s.contacts}">
                            ${Resources.t("words.products.contacts")}
                            <p class="${s.plus}">
                                ${Resources.t("words.soon")}
                            </p>
                        </div>
                        -->
                    </div>
                </div>
                <div class="${s.contentContainer}">

                </div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.contentContainer);
        this.contentContainer.render(new WebsiteSliderFlatComponent({
            buttons: false, components: [
                {func: () => new WebsiteSliderCardBlogComponent(), button: this.el(s.blog)},
                /*
                {func: () => new WebsiteSliderCardInboxComponent(), button: this.el(s.inbox)},
                {
                    func: () => new WebsiteSliderCardLiveChatComponent({
                        image: "illus-livechats-2.webp"
                    }), button: this.el(s.livechat)
                },
                {func: () => new WebsiteSliderCardMailsComponent(), button: this.el(s.mails)},
                {func: () => new WebsiteSliderCardHelpCenterComponent(), button: this.el(s.frHelpcenter)},
                {func: () => new WebsiteSliderCardContactsComponents(), button: this.el(s.contacts)}

                 */
            ]
        }));
        super.commit();
    }
}