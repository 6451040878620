import 'regenerator-runtime/runtime';
import {SedestralLogic} from "./sedestral-interface-modules/sedestral-interface-component/SedestralLogic";
import {config} from "./config";
import {Network} from "./network/Network";
import {Website} from "./products/website/Website";
import {WebsiteNetwork} from "./products/website/network/WebsiteNetwork";
import {ProductName} from "./models/product/ProductName";
import {ProductType} from "./models/product/ProductType";
import {SedestralSsr} from "./sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {Resources} from "./resources/Resources";
import {EntityService} from "./services/entity/EntityService";
import {AccountService} from "./services/account/AccountService";
import Hotjar from '@hotjar/browser';

config.import("website");
SedestralLogic.ready(async () => {
    await SedestralLogic.init();
    await WebsiteNetwork.init();
    await Network.init(ProductName.toString(ProductType.WEBSITE));
    await Website.init();

    if (!SedestralSsr.hasSsr() && !/Googlebot|Spiders|Crawlers/i.test(navigator.userAgent)) {

        let params: any = {language: Resources.language};

        if (EntityService.activeTokenCookie !== null) {

            const accountMe = await AccountService.findByMe(EntityService.activeTokenCookie);
            if (accountMe) {

                if (accountMe.id) {
                    params.identifier = accountMe.id;
                }

                if (accountMe.name) {
                    params.name = accountMe.name;
                }

                if (accountMe.email) {
                    params.email = accountMe.email;
                }

                if (accountMe.avatarUrl) {
                    params.avatarUrl = accountMe.avatarUrl;
                }

            } else {
                EntityService.clearTokenCookie();
            }
        }

        window["SEDESTRAL_ID"] = config.livechatId;
        window["SEDESTRAL_PARAMS"] = params;

        try {
            (function () {
                let v = "v", d = document, s;
                try {
                    v = localStorage.getItem("sdt-v");
                } catch (e) {
                }
                s = d.createElement("script");
                s.async = true;
                s.src = config.livechatJsUrl() + v;
                d.head.appendChild(s);
            })();
        } catch (e) {
            console.error(e);
        }

        try {
            if (!config.isDev && !SedestralSsr.hasSsr()) {
                const siteId = config.hotjarId;
                const hotjarVersion = 6;
                Hotjar.init(siteId, hotjarVersion);
            }
        } catch (e) {
            console.error(e);
        }

    }
});