import * as s from "./website-head-submenu-content.scss";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {CapsuleComponent} from "../../../../../../panel/components/components/capsule/CapsuleComponent";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";

export class WebsiteHeadSubmenuContentProductsComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHeadSubmenuContent}">
                <div class="${s.column}">
                        <!--
                    <h6 class="${s.title}">
                        ${Resources.t("words.communications")}

                        <span class="${s.arrow}"></span>
                    </h6>
                    -->
                    <div class="${s.cases}">
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('smartblog')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.BLOG))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.products.blog")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.blogTitle")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <div class="${s.line}"></div>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('smartblog/aiwrite')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.writing}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.aiWriterTitle")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.aiWriterDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('smartblog/autopilot')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.autopilot}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.autopilotTitle")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.autopilotDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('smartblog/style')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.style}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.styleCloningTitle")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.styleCloningDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        
                            <!--
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('inbox')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.INBOX))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.products.inbox")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.omnichannelInbox")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('livechat')}">
                            <div class="${s.case} ${s.livechat}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.LIVECHAT))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.products.livechat")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.simplifyYourRelationship")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('mail')}">
                            <div class="${s.case} ${s.mail}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.MAILS))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.products.mails")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.manageMails")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        -->
                    </div>
                </div>
                    <!--
                <div class="${s.column}">
                    <h6 class="${s.title}">
                        ${Resources.t("words.clientReport")}
                        <span class="${s.arrow}"></span>
                    </h6>
                    <div class="${s.cases}">
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('statistics')}">
                            <div class="${s.case} ${s.statistics}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.STATISTICS))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.products.statistics")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.overviewOfPerformance")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <div class="${s.case} ${s.helpcenter}">
                            <div class="${s.capsuleContainer}">
                                ${this.draw(new CapsuleComponent(ProductType.HELPCENTER))}
                            </div>
                            <div class="${s.texts}">
                                <p itemprop="name" class="${s.caseTitle}">
                                    ${Resources.t("words.products.helpCenter")}
                                </p>
                                <p class="${s.description}">
                                    ${Resources.t("words.importantInformationAccessible")}
                                </p>
                            </div>
                        </div>
                        <div class="${s.case} ${s.contacts}">
                            <div class="${s.capsuleContainer}">
                                ${this.draw(new CapsuleComponent(ProductType.CONTACTS))}
                            </div>
                            <div class="${s.texts}">
                                <p itemprop="name" class="${s.caseTitle}">
                                    ${Resources.t("words.products.contacts")}
                                </p>
                                <p class="${s.description}">
                                    ${Resources.t("words.consolidateCustomerData")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                -->
            </div>
        `;
    }
}