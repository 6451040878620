@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteReview {
  width: 50%;
  background: var(--grey-sur);
  padding: 25px;
  border-radius: 14px;
  flex: 1 1 calc(50% - 25px);
  box-sizing: border-box;

  .stars {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 10px;

    .star {
      width: 18px;
      height: 18px;
      margin-right: 1px;
      display: inline-block;

      @include icons.ico("black-star");
    }

    .provider {
      margin-left: auto;
      color: var(--grey-text-very-dark);
      display: flex;
      align-items: center;

      .by {
        margin-right: 4px;
      }

      &.shopify {
        .icon {
          @include icons.ico("colored-shopify");
        }
      }

      &.google {
        .icon {
          @include icons.ico("colored-google");
        }
      }

      .icon {
        height: 16px;
        width: 16px;
        margin-left: 6px;
        background: red;
      }
    }
  }

  .content {
    color: var(--grey-text-very-dark);
    font-size: 17px;
  }

  .author {
    display: flex;
    margin-top: 25px;
    align-items: center;
    gap: 15px;

    .image {
      height: 44px;
      width: 44px;
      border-radius: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .name {
      font-size: 19px;

      @include texts.font("semi-bold");
    }

    .description {
      font-size: 15px;

      color: var(--grey-text-very-dark);
    }
  }
}