import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./offer-content.scss";
import {
    IOfferProductBundleTemplateModel
} from "../../../../../../models/offer/product/bundles/IOfferProductBundleTemplateModel";
import {IOfferProductTemplateModel} from "../../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {Resources} from "../../../../../../resources/Resources";

export class OfferContentComponent extends Component {

    public bundle: IOfferProductBundleTemplateModel;
    public offersContainer: Component;
    public templateProduct: IOfferProductTemplateModel;

    public contents: string[];

    constructor(templateProduct: IOfferProductTemplateModel, contents: string[], text: string) {
        super();
        this.templateProduct = templateProduct;
        this.contents = contents;

        //language=HTML
        this.template = `
            <div class="${s.componentOfferContent}">
                ${text ? `<div class="${s.title}">${text}</div>` : ``}
                <ul class="${s.offers}"></ul>
            </div>
        `;
    }

    commit() {
        this.offersContainer = this.el(s.offers);
        this.contents.forEach(value => {
            this.offersContainer.append(`<li>${value}</li>`);
        });
        this.additionalProducts();
        super.commit();
    }

    additionalProducts() {
        switch (this.templateProduct.id) {
            case "ed265780416841f491bd3024d736b935":
                this.offersContainer.append(`<li>${Resources.t("words.offerCommunityImageArticle")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerGpt40DallE3")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerOneAccount")}</li>`);
                break;

            case "17778c6ca54349a69a4aa7ca218aced9":
                this.offersContainer.append(`<li>${Resources.t("words.offerCommunityImageArticle")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerGpt35DallE3")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerUnlimitedAccounts")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerCustomerSupport")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.wordpressShopifyWixExtensions")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerCollabEditor")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerManageAuthors")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerCloneText")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerBlogContext")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerSeoQuality")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerKeywords")}</li>`);
                break;

            case "406081e6341740e097141d18ed839e0e":
                this.offersContainer.prepend(`<li class="${s.from}">${Resources.t("words.allBasicFeatures")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerGpt40DallE3")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerCommunityImageArticlePro")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.offerAccompaniment")}</li>`);
                this.offersContainer.append(`<li>${Resources.t("words.autopilotProduct")}</li>`);
                break;

        }
    }
}