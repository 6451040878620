import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./website-content-pricing.scss";
import {WebsiteIssuesComponent} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {OfferComponent} from "../../../../../../panel/components/components/offer/OfferComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class WebsiteContentPricingComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentPricing}">
                <div style="gap:20px;" class="${s.content}">
                    <h2 class="${r.title}">
                        ${Resources.t("words.sedestralRates")}
                    </h2>
                    <div class="${r.offer}">
                        ${this.draw(new OfferComponent(ProductType.BLOG,undefined,true))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.pricing")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}