import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-head.scss";
import {Network} from "../../../../../network/Network";
import {Resources} from "../../../../../resources/Resources";
import {WebsiteHeadSubmenuModelsProductsComponent} from "./submenu/models/WebsiteHeadSubmenuModelsProductsComponent";
import {WebsiteHeadSubmenuModelsUseCaseComponent} from "./submenu/models/WebsiteHeadSubmenuModelsUseCaseComponent";
import {WebsiteHeadSubmenuContentMobileComponent} from "./submenu/content/WebsiteHeadSubmenuContentMobileComponent";

export class WebsiteHeadComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHead}">
                <div style="z-index: 4;" itemscope itemtype="https://schema.org/Organization">
                    <a itemprop="url" href="${process.env.APP_URL + "/" + Resources.language + "/home"}">
                        <meta itemprop="brand" content="Sedestral">
                        <div class="${s.caseLogo}">
                            <div itemprop="logo" class="${s.logoContainer}">
                                <img itemprop="logo" class="${s.logo}"
                                     src="${Network.vendor}images/logo-sedestral.svg"
                                     alt="${Resources.t("words.logo")}">
                            </div>
                            <h2 itemprop="name" class="${s.title}">
                                Sedestral
                            </h2>
                        </div>
                    </a>
                </div>
                <div itemscope itemtype="https://schema.org/SiteNavigationElement" class="${s.cases}">
                    <div class="${s.case} ${s.products}">
                        ${Resources.t("words.productsName")}
                        <i class="${s.icon}"></i>

                        <div class="${s.subMenu}">
                            <div class="${s.separator}"></div>
                            ${this.draw(new WebsiteHeadSubmenuModelsProductsComponent())}
                        </div>
                    </div>
                    <div style="display: none;" class="${s.case} ${s.use}">
                        ${Resources.t("words.useCases")}
                        <i class="${s.icon}"></i>

                        <div class="${s.subMenu}">
                            <div class="${s.separator}"></div>
                            ${this.draw(new WebsiteHeadSubmenuModelsUseCaseComponent())}
                        </div>
                    </div>
                    <div class="${s.case} ${s.pricing}">
                        <a href="${Network.router.pages.getUrlWithPath('pricing')}">${Resources.t("words.pricing")}</a>
                    </div>
                    <div class="${s.case} ${s.schedule}">
                        <a href="${Network.router.pages.getUrlWithPath('schedule')}">${Resources.t("words.scheduleTitle")}</a>
                    </div>
                    <!--<div class="${s.case} ${s.blog}">
                        <a target="_blank" href="${process.env.BLOG_URL}">Blog</a>
                    </div>-->
                </div>
                <a class="${s.caseAccount}" href="${Network.router.pages.getPanelUrl('signup')}" target="_blank">
                    ${Resources.t("words.begin")}
                </a>
                <div class="${s.casesMobile}">
                    <div class="${s.caseMobile} ${s.bars}">
                        <span class="${s.icon}"></span>
                    </div>
                </div>
                <div class="${s.mobile}"></div>
            </div>
        `;
    }

    commit() {
        this.registerSubMenu(this.el(s.products));
        this.registerSubMenu(this.el(s.use));

        this.registerMobile();

        //this.el(s.blog).onClick(() => window.open(process.env.BLOG_URL));
        this.el(s.pricing).onClick(() => Network.router.pages.redirect("/pricing"));
        this.el(s.schedule).onClick(() => Network.router.pages.redirect("/schedule"));

        super.commit();
    }

    registerSubMenu(caseButton: Component) {
        let subMenu = caseButton.el(s.subMenu);
        caseButton.onHover(() => subMenu.scaleShow());
        caseButton.onLeave(() => subMenu.scaleHide(80, 0.9, false));

        Network.router.pages.onPopState(this, () => subMenu.scaleHide(80, 0.9, false))
    }

    registerMobile() {
        let mobileCase = this.el(s.bars);
        let mobileContainer = this.el(s.mobile);

        let hideMenu = () => {
            mobileContainer.displayHide();
            mobileContainer.clear();
            mobileCase.removeClass(s.open);
        }

        let showMenu = () => {
            let mobileMenuComponent = new WebsiteHeadSubmenuContentMobileComponent();
            mobileContainer.render(mobileMenuComponent);
            mobileContainer.displayShow();
            mobileMenuComponent.onOpen();
            mobileCase.addClass(s.open);
        }

        mobileContainer.onOutsideClick(() => hideMenu());
        mobileCase.onClick(() => {
            if (mobileContainer.isDisplayNone()) {
                showMenu();
            } else {
                hideMenu();
            }
        }, true);

        Network.router.pages.onPopState(this, () => hideMenu());
    }
}