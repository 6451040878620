import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-pages.scss";
import {WebsiteHeadComponent} from "../head/WebsiteHeadComponent";
import {WebsiteJoinComponent} from "../join/WebsiteJoinComponent";
import {WebsiteFooterComponent} from "../footer/WebsiteFooterComponent";
import {
    PageComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-router/pages/types/PageComponent";
import * as l from "../../../../panel/components/pages/page.scss";
export class WebsitePageComponent extends PageComponent {

    public headFixed: Component;

    constructor() {
        super();
        //language=HTML
        this.template = `
        `;
    }

    commit() {
        this.headFixed = this.el(s.headFixed);

        this.putListener(window, "scroll", (e) => {
            if (window.scrollY > 0) {
                this.headFixed.addClass(s.fixed);
            } else {
                this.headFixed.removeClass(s.fixed);
            }
        })

        super.commit();
    }

    contentComponent(): Component {
        return undefined;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${l.page}">
                <div class="${s.componentWebsitePage}">
                <div class="${s.head}">
                    <div class="${s.headFixed}">
                        <div class="${s.content}">
                            <div class="${s.container}">
                                ${this.draw(new WebsiteHeadComponent())}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="${s.content}">
                    <div class="${s.contents}">
                        <div class="${s.container}">
                            ${this.draw(this.contentComponent())}
                        </div>
                        <div class="${s.container}">
                            ${this.draw(new WebsiteJoinComponent())}
                        </div>
                    </div>
                </div>
                <div class="${s.container} ${s.footerContainer}">
                    ${this.draw(new WebsiteFooterComponent())}
                </div>
            </div>
            </div>
        `;
    }

    onHash(): void {
    }
}