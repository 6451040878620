import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {IOfferDetailsModel} from "../../../../../../models/offer/IOfferDetailsModel";
import * as s from "./offer-quote.scss";
import {OfferService} from "../../../../../../services/offer/OfferService";
import {OfferContentComponent} from "../content/OfferContentComponent";
import {OfferPriceComponent} from "../price/OfferPriceComponent";
import {
    VisualInputStatesComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/input/states/VisualInputStatesComponent";
import {Resources} from "../../../../../../resources/Resources";
import {ProductType} from "../../../../../../models/product/ProductType";
import {OfferTemplateIconComponent} from "../template/icon/OfferTemplateIconComponent";
import {
    ButtonCheckBoxComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/check/box/ButtonCheckBoxComponent";
import {Network} from "../../../../../../network/Network";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {IOfferProductModel} from "../../../../../../models/offer/product/IOfferProductModel";
import {IOfferProductBundleModel} from "../../../../../../models/offer/product/bundles/IOfferProductBundleModel";
import {
    dateFormat
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormat";
import {OfferPaymentActionType} from "../../../../../../models/offer/payment/OfferPaymentActionType";
import {OfferState} from "../../../../../../models/offer/OfferState";
import {IOfferModel} from "../../../../../../models/offer/IOfferModel";
import {SiteAddressComponent} from "../../site/address/SiteAddressComponent";
import {AccountSessionService} from "../../../../../../services/account/session/AccountSessionService";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {PaymentProvider, PaymentProviderType} from "../../../../../../models/payment/PaymentProviderType";

export class OfferQuoteComponent extends Component {

    public productType: ProductType;
    public quote: IOfferDetailsModel;
    public product: IOfferProductModel;
    public bundle: IOfferProductBundleModel;

    public backButton: Component;
    public priceComponent: OfferPriceComponent;

    public advantageCode: VisualInputStatesComponent;
    public cgvButton: ButtonCheckBoxComponent;
    public addressComponent: SiteAddressComponent;

    public paymentProvider: PaymentProvider;

    constructor(productType: ProductType, quote: IOfferDetailsModel) {
        super();
        this.productType = productType;
        this.quote = quote;
        this.paymentProvider = EntityService.activeSite.paymentProvider;

        this.product = this.quote.offer.products[this.productType];
        this.bundle = this.quote.offer.products[this.productType].bundles[0];

        //language=HTML
        this.template = `
            <div class="${s.componentOfferQuote}"></div>`;
    }

    commit() {
        this.renderAll();

        this.scrollable();
        super.commit();
    }

    renderAll() {
        this.renderBack(Resources.t(`words.offerBack`));
        this.renderOfferContent();
        this.renderAddress();
        this.renderAdvantageCode();

        if (this.quote.offer.startDate > Date.now()) {
            this.renderScheduled();
        }

        this.renderPrice();
    }

    renderBack(text: string) {
        if (!this.quote.offer.active) {
            //language=HTML
            this.backButton = this.append(`
            <div class="${s.back}">
                <div class="${s.icon}"></div>
                ${text}
            </div>
        `);
            this.backButton.onClick(async () => this.back());
        } else {
            this.append(`
            <div class="${s.back}"></div>
        `);
        }
    }

    renderOfferContent() {
        let contents = this.bundle.solutions.map(solution => {
            return `${solution.quantity} ${Resources.t(`words.offerSolution${this.product.template.level}${this.product.template.productType}${solution.type}`)}`;
        });

        //language=HTML
        this.append(`
            <div class="${s.contentContainer}">
                <div class="${s.template}">
                    <div class="${s.iconContainer}">
                        ${this.draw(new OfferTemplateIconComponent(this.product.template.type))}
                    </div>
                    <div class="${s.name}">
                        ${Resources.t(`words.offer`)} ${Resources.t(`words.offerName${this.product.template.type}`)}
                    </div>
                </div>
                ${this.draw(new OfferContentComponent(this.product.template, contents, Resources.t("words.offerContentRecap")))}
            </div>
        `);
    }

    renderAddress() {
        this.cgvButton = new ButtonCheckBoxComponent(false);
        this.addressComponent = new SiteAddressComponent();

        //language=HTML
        this.append(`
            <div class="${s.inputs}">
                ${this.paymentProvider.address ? `
                <div class="${s.label}">
                    ${Resources.t("words.offerWriteAddress")}
                </div>
                ${this.draw(this.addressComponent)}
            ` : ''}

                <div class="${s.label}">
                    <a href="${Network.router.pages.getWebsiteUrl('cgv')}" target="_blank">
                        ${Resources.t("words.offerCgv")}
                    </a>
                    <span>${this.draw(this.cgvButton)}</span>
                </div>
            </div>
        `);
    }

    renderAdvantageCode() {
        this.advantageCode = new VisualInputStatesComponent({placeholder: Resources.t("words.offerApplyAdvantageCodeInput")});

        //language=HTML
        this.append(`
            <div class="${s.advantageCode}">
                <div>${Resources.t("words.offerApplyAdvantageCode")}</div>
                <div class="${s.inputContainer}">
                    ${this.draw(this.advantageCode)}
                </div>
            </div>
        `);
    }

    renderScheduled() {
        //language=HTML
        this.append(`
            <div class="${s.scheduled}">
                ${Resources.t("words.offerTakeEffectAt")} ${dateFormat(new Date(this.quote.offer.startDate))}
            </div>
        `);
    }

    renderPrice(fixedPrice?: number, text?: string) {
        this.priceComponent = new OfferPriceComponent({
            period: {
                periodType: this.quote.offer.periodType,
                advantagePercentage: this.quote.offer.advantagePercentage
            },
            price: fixedPrice ? fixedPrice : this.quote.offer.totalPrice,
            noCalculateYear: true,
            text: text ? text : Resources.t("words.continue"),
            totalPrice: fixedPrice != undefined
        });
        this.priceComponent.onGetStarted = async () => this.getStarted();

        //language=HTML
        this.append(`
            <div style="margin-bottom: 20px;">
                ${this.draw(this.priceComponent)}
            </div>
        `);
    }

    async back() {
        this.clearAll();
        this.render(new LoaderLightComponent());
        await OfferService.quoteCancel(this);
        this.onStep();
    }

    async getStarted() {
        if (!this.cgvButton.checked) {
            Network.router.static.components.notifications.notify(Resources.t("words.offerAcceptCgv"), undefined, 3000);
        } else {
            this.priceComponent.setLoading();

            let quote = await OfferService.quoteConfirmed({
                offerId: this.quote.offer.id,
                address: this.paymentProvider.address ? this.addressComponent.getAddress() : undefined,
                advantageCode: this.advantageCode.getValue().length > 0 ? this.advantageCode.getValue() : undefined
            }, this);

            if (quote) {

                await AccountSessionService.token();

                if (this.paymentProvider.id == PaymentProviderType.STRIPE && quote.payment?.actionType == OfferPaymentActionType.NONE) {
                    await this.startStripePayCheck(quote.offer);
                } else {
                    this.onStep(quote);
                    this.priceComponent.remLoading();
                }

            } else {
                this.priceComponent.remLoading();
            }
        }
    }

    scheduleExpiredQuote() {
        if (this.quote.offer.expiredTime > 0) {
            let time = this.quote.offer.expiredTime - Date.now();
            this.timeOut(() => this.back(), time);
        }
    }

    async startStripePayCheck(offerQuote: IOfferModel) {

        if (offerQuote.state === OfferState.SCHEDULED) {
            this.onCloseModal();
            Network.router.static.components.notifications.notify(Resources.t("words.offerNowScheduled"), undefined, 3000, "success");
            await this.reloadOrRedirect();
        } else {

            let intervalCount = 0;
            let interval = this.interval(async () => {

                if (intervalCount >= 3) {
                    Network.router.static.components.notifications.notify(Resources.t("words.account.error"), undefined, 3000);
                    clearInterval(interval);
                    this.priceComponent.remLoading();
                    return;
                }

                intervalCount++;

                let offerDetails = await OfferService.active(false, this);
                if (offerDetails && offerDetails.offer.id == offerQuote.id) {
                    clearInterval(interval);
                    this.onCloseModal();
                    Network.router.static.components.notifications.notify(Resources.t("words.offerNowActive"), undefined, 3000, "success");
                    await this.reloadOrRedirect();
                }
            }, 7000);
            this.priceComponent.setLoading();
        }
    }

    onStep(details?: IOfferDetailsModel) {

    }

    onCloseModal() {

    }

    async reloadOrRedirect() {
        if (Network.router.pages.getPath().includes("/general/subscriptions")) {
            await Network.router.pages.reload();
        } else {
            await Network.router.pages.redirect("/general/subscriptions");
        }
    }
}