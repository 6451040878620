@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsitePresentation {
  display: flex;
  flex-direction: row;
  gap: 81.5px;

  .left {
    width: 40%;
    min-width: 434px;

    .title {
      background: var(--grad-multi);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 52px;

      @include texts.font("extra-bold");
    }

    .description {
      margin-top: 17px;
      font-size: 18px;

      @include texts.font("semi-bold");
    }

    .startButton {
      display: flex;
      flex-direction: row;
      gap: 15px;
      float: right;
      margin-top: 20px;
      padding: 16.5px 21px;
      background: var(--grey-sur);
      border-radius: 37.5px;
      font-size: 20px;
      cursor: pointer;
      align-items: center;
      color: var(--black);

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur-hover);
      }

      .icon {
        margin-top: 4px;
        width: 16px;
        height: 16px;

        @include icons.ico("black-right-arrow");
      }
    }
  }

  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 40px;
    .left {
      width: 100%;
      min-width: 0;

      .title {
        font-size: 38px;
      }

      .description {
        margin-top: 10px;
        font-size: 15px;
      }
    }

    .right {
      width: 100%;
    }
  }
}