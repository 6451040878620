import {SedestralRouter} from "../../../sedestral-interface-modules/sedestral-interface-router/SedestralRouter";
import {NotFoundPage} from "../../panel/components/pages/global/notfound/NotFoundPage";
import {LoaderStatic} from "../../panel/components/static/loaders/LoaderStatic";
import {WebsiteInboxPage} from "./pages/WebsiteInboxPage";
import {WebsitePricingPage} from "./pages/WebsitePricingPage";
import {WebsiteLivechatPage} from "./pages/WebsiteLivechatPage";
import {WebsiteMailPage} from "./pages/WebsiteMailPage";
import {WebsiteClientServicePage} from "./pages/WebsiteClientServicePage";
import {WebsiteStatisticsPage} from "./pages/WebsiteStatisticsPage";
import {WebsiteCgvPage} from "./pages/WebsiteCgvPage";
import {WebsiteHomePage} from "./pages/WebsiteHomePage";
import {WebsiteCguPage} from "./pages/WebsiteCguPage";
import {WebsitePolicyPage} from "./pages/WebsitePolicyPage";
import {
    ISedestralRouterConfig
} from "../../../sedestral-interface-modules/sedestral-interface-router/types/ISedestralRouterConfig";
import {WebsitePublisherPage} from "./pages/WebsitePublisherPage";
import {WebsiteHelpcenterPage} from "./pages/WebsiteHelpcenterPage";
import {WebsiteBlogPage} from "./pages/WebsiteBlogPage";
import {WebsiteSchedulePage} from "./pages/WebsiteSchedulePage";
import {WebsiteBlogWritingPage} from "./pages/WebsiteBlogWritingPage";
import {WebsiteBlogAutopilotPage} from "./pages/WebsiteBlogAutopilotPage";
import {WebsiteBlogStylePage} from "./pages/WebsiteBlogStylePage";
import {WebsiteBlogWixPage} from "./pages/WebsiteBlogWixPage";
import {WebsiteBlogShopifyPage} from "./pages/WebsiteBlogShopifyPage";
import {
    WebsiteContentBlogPrestashopComponent
} from "./components/content/pages/blog/WebsiteContentBlogPrestashopComponent";
import {WebsiteBlogPrestashopPage} from "./pages/WebsiteBlogPrestashopPage";

export class WebsiteRouter extends SedestralRouter {
    async init(config?: ISedestralRouterConfig): Promise<void> {
        super.init(config);
        super.initStarted();
    }

    registerPages() {
        this.pages.registerPage("/404", () => new NotFoundPage());
        this.pages.registerPage("/home", () => new WebsiteHomePage());
        this.pages.registerPage("/inbox", () => new WebsiteInboxPage());
        this.pages.registerPage("/pricing", () => new WebsitePricingPage());
        this.pages.registerPage("/livechat", () => new WebsiteLivechatPage());
        this.pages.registerPage("/mail", () => new WebsiteMailPage());
        this.pages.registerPage("/publisher", () => new WebsitePublisherPage());
        this.pages.registerPage("/helpcenter", () => new WebsiteHelpcenterPage());
        this.pages.registerPage("/service-client", () => new WebsiteClientServicePage());
        this.pages.registerPage("/statistics", () => new WebsiteStatisticsPage());
        this.pages.registerPage("/schedule", () => new WebsiteSchedulePage());
        this.pages.registerPage("/cgv", () => new WebsiteCgvPage());
        this.pages.registerPage("/smartblog", () => new WebsiteBlogPage());
        this.pages.registerPage("/smartblog/aiwrite", () => new WebsiteBlogWritingPage());
        this.pages.registerPage("/smartblog/autopilot", () => new WebsiteBlogAutopilotPage());
        this.pages.registerPage("/smartblog/style", () => new WebsiteBlogStylePage());
        this.pages.registerPage("/smartblog/wix", () => new WebsiteBlogWixPage());
        this.pages.registerPage("/smartblog/shopify", () => new WebsiteBlogShopifyPage());
        this.pages.registerPage("/smartblog/prestashop", () => new WebsiteBlogPrestashopPage());
        this.pages.registerPage("/cgu", () => new WebsiteCguPage());
        this.pages.registerPage("/policy", () => new WebsitePolicyPage());

        this.pages.path404 = "/404";
        this.pages.pathHome = "/home";
        this.pages.pathLogin = "/home";
    }

    registerStatic() {
        this.static.register("loader", new LoaderStatic());
    }

    onLogin() {
        this.pages.redirect(this.pages.pathHome);
    }

    onLogout() {
        setTimeout(() => {
            this.pages.redirect(this.pages.pathLogin);
        }, 3000);
    }
}