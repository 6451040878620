import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-footer.scss";
import {
    CapsuleProductsComponent
} from "../../../../panel/components/components/capsule/products/CapsuleProductsComponent";
import {Network} from "../../../../../network/Network";
import {Resources} from "../../../../../resources/Resources";
import {
    LanguageWebsitePickerComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/language/website/LanguageWebsitePickerComponent";

export class WebsiteFooterComponent extends Component {

    public languagePicker: LanguageWebsitePickerComponent;

    constructor() {
        super();
        this.languagePicker = new LanguageWebsitePickerComponent(Resources.language);

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteFooter}">
                <div class="${s.brand}">
                    <h2 class="${s.name}">
                        Sedestral
                    </h2>
                    <div class="${s.capsuleContainer}">
                        ${this.draw(new CapsuleProductsComponent(true))}
                    </div>
                </div>
                <div itemscope itemtype="https://schema.org/SiteNavigationElement" class="${s.columns}">
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.productsName")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry} ${s.smartBlog}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('smartblog')}">
                                        ${Resources.t("words.products.blog")}
                                    </a>
                                </li>
                                <li style="display: none" itemprop="name" class="${s.entry} ${s.inbox}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('inbox')}">
                                        ${Resources.t("words.products.inbox")}
                                    </a>
                                </li>
                                <li style="display: none" itemprop="name" class="${s.entry} ${s.livechat}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('livechat')}">
                                        ${Resources.t("words.products.livechat")}
                                    </a>
                                </li>
                                <li style="display: none" itemprop="name" class="${s.entry} ${s.mail}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('mail')}">
                                        ${Resources.t("words.products.mails")}
                                    </a>
                                </li>
                                <li style="display: none" itemprop="name" class="${s.entry} ${s.statistics}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('statistics')}">
                                        ${Resources.t("words.products.statistics")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div style="display:none;" class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.useCases")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry} ${s.customerService}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('service-client')}">
                                        ${Resources.t("words.customerService")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.resources")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry} ${s.pricing}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('pricing')}">
                                        ${Resources.t("words.pricing")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry} ${s.schedule}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('schedule')}">
                                        ${Resources.t("words.scheduleTitle")}
                                    </a>
                                </li>
                                <!--<li itemprop="name" class="${s.entry} ${s.blog}">
                                    <a target="_blank" itemprop="url" href="${process.env.BLOG_URL}">
                                        Blog
                                    </a>
                                </li>-->
                                <li itemprop="name" class="${s.entry} ${s.conditionsOfUse}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('cgu')}">
                                        ${Resources.t("words.conditionsOfUse")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry} ${s.generalSalesConditions}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('cgv')}">
                                        ${Resources.t("words.generalSalesConditions")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry} ${s.privacyPolicy}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('policy')}">
                                        ${Resources.t("words.privacyPolicyName")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.account")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry} ${s.customerService}">
                                    <a itemprop="url" href="${Network.router.pages.getPanelUrl('login')}" target="_blank">
                                        ${Resources.t("words.myAccount")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry} ${s.customerService}">
                                    <a itemprop="url" href="${Network.router.pages.getPanelUrl("signup")}" target="_blank">
                                        ${Resources.t("words.signUp")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.language")}
                            <div class="${s.language}">
                                ${this.draw(this.languagePicker)}
                            </div>
                        </nav>
                    </div>
                </div>
                <p class="${s.copyright}">
                    Copyright © ${new Date().getFullYear()} Sedestral. ${Resources.t("words.allRightsReserved")}
                </p>
            </div>
        `;
    }

    commit() {
        //this.el(s.blog).onClick(() => window.open(process.env.BLOG_URL));
        this.el(s.pricing).onClick(() => Network.router.pages.redirect("/pricing"));
        this.el(s.smartBlog).onClick(() => Network.router.pages.redirect("/smartblog"));
        this.el(s.schedule).onClick(() => Network.router.pages.redirect("/schedule"));
        this.el(s.inbox).onClick(() => Network.router.pages.redirect("/inbox"));
        this.el(s.mail).onClick(() => Network.router.pages.redirect("/mail"));
        this.el(s.statistics).onClick(() => Network.router.pages.redirect("/statistics"));
        this.el(s.livechat).onClick(() => Network.router.pages.redirect("/livechat"));
        this.el(s.privacyPolicy).onClick(() => Network.router.pages.redirect("/policy"));
        this.el(s.customerService).onClick(() => Network.router.pages.redirect("/service-client"));
        this.el(s.conditionsOfUse).onClick(() => Network.router.pages.redirect("/cgu"));
        this.el(s.generalSalesConditions).onClick(() => Network.router.pages.redirect("/cgv"));

        this.languagePicker.onSelect = language => Network.router.pages.setLanguage(language);
        super.commit();
    }
}