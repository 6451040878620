@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteSliderCard {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 19.55px;
  flex-direction: row;
  gap: 100px;

  .left {
    width: 50%;
    padding: 30px 0;
    overflow: hidden;

    .article {

      .title {
        margin-top: 30px;
        background: var(--grad-black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 44px;

        @include texts.font("bold");
      }

      .subtitle {
        margin-top: 38px;
        font-size: 18px;

        @include texts.font("semi-bold");
      }

      .more {
        padding: 19px 21px;
        background: var(--grey-sur);
        margin-top: 20px;
        border-radius: 34px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        font-size: 18px;
        color: var(--black);

        @include texts.font("semi-bold");

        .arrow {
          width: 13px;
          height: 13px;
          margin: 4px 0 0 10px;

          @include icons.ico("black-right-bold");
        }
      }
    }
  }

  .right {
    width: 50%;
    padding: 30px 0;
    display: flex;
    align-items: center;

    .visualInbox {
      .funnel {
        width: 100%;
        height: auto;
      }

      .image {
        margin-right: 46px;
        position: relative;
        right: 18px;
        box-shadow: 0px 0px 18px var(--black-alpha-middle);
        border-radius: 14px;
        margin-top: 0;
        width: 100%;
        height: 50%;
      }
    }

    .visualLivechat {
      width: 100%;
      height: 100%;
      margin-top: 33px;
      display: flex;
      justify-content: center;

      .image {
        width: 100%;
      }
    }

    .visualMails {
      width: 100%;
      height: 100%;
      background-size: contain !important;
      background-repeat: no-repeat !important;

      @include icons.ico("illus-mails");
    }

    .visualHelpCenter {
      width: calc(100% - 40px);
      height: 100%;
      position: relative;
      margin-top: 120px;
      padding: 0 20px;
    }

    .visualContacts {
      width: calc(100% - 40px);
      height: 100%;
      position: relative;
      padding: 0 20px;
    }
  }

  @media (max-width: 1115px) {
    gap: 50px;

    .left {
      .article {
        .title {
          font-size: 32px;
        }

        .subtitle {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0;
    position: relative;

    .left {
      padding: 27px 35px 10px 35px;
      width: calc(100% - 70px);
      height: 40%;

      .article {

        .title {
          font-size: 20px;
        }

        .subtitle {
          display: none;
        }

        .more {
          font-size: 12px;

          .arrow {
            width: 10px;
            height: 10px;
            margin: 3px 0 0 10px;
          }
        }
      }
    }

    .right {
      padding: 10px 35px 48px 35px;
      text-align: center;
      max-width: 100%;
      width: calc(100% - 70px);
      height: 100%;

      .icons {
        width: 50%;
      }

      .visual {
        width: 100%;
        height: 50%;
        margin-top: 33px;
        box-shadow: 0 4.24 52.97px var(--black-alpha-middle);
        object-fit: contain;
      }

      .visualInbox {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image {
          margin-top: 10px;
          right: 0 !important;
          margin-right: 0 !important;
        }
      }

      .visualLivechat {
        margin-top: 10px;
      }

      .visualMails {
        width: 90%;
        height: 160px;
        margin-left: 5%;
      }

      .visualHelpCenter {
        margin-top: 10px;
        padding: 0;
        width: 100%;
      }
    }
  }
}