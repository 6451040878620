import {WebsiteSliderCardComponent} from "../WebsiteSliderCardComponent";
import * as s from "../website-slider-card.scss"
import {WebsiteImageSkewedComponent} from "../../../image/skewed/WebsiteImageSkewedComponent";
import {ProductType} from "../../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../../network/Network";
import {Resources} from "../../../../../../../../resources/Resources";
import {NetworkVendor} from "../../../../../../../../network/NetworkVendor";

export class WebsiteSliderCardBlogComponent extends WebsiteSliderCardComponent {

    constructor() {
        super({
            productType: ProductType.BLOG,
            title: Resources.t("words.blogTitle"),
            subtitle: Resources.t("words.blogDescription"),
            url: process.env.APP_URL + "/smartblog"
        });
    }

    renderRight() {
        //language=HTML
        this.rightContainer.append(`
            <div class="${s.visualInbox}">
                <img class="${s.funnel}"
                     src="${Network.vendor}images/illus-ai.svg"
                     alt="${Resources.t("words.differentSocialNetworks")}"
                     width="439"
                     height="242"
                />
                <div class="${s.image}">
                    ${this.draw(new WebsiteImageSkewedComponent({
                        link: NetworkVendor.image("blog-c.webp"),
                        shadow: "discreet",
                        width: `525`,
                        height: `295.31`
                    }))}
                </div>
            </div>

        `);
    }
}