@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentOfferTemplate {

  &.premium {
    .price {
      background: var(--offer-paid) !important;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 15px;

    .name {
      font-size: 14px;

      @include texts.font("semi-bold");
    }

    .icon {
      height: 44px;
      width: 44px;
      position: relative;
    }

    .price {
      font-size: 18px;
      width: 164px;
      height: 36px;
      background: var(--offer-free);
      border-radius: 10px;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;

      @include texts.font("bold");

      &.paid {
        color: var(--offer-paid);
      }
    }

    .description {
      text-align: center;
      font-size: 14px;
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      min-height: 100px;

      .engage{
        font-size: 12px;
        margin-bottom: 10px;
        color: var(--grey-text-very-dark);
      }
    }
  }

  .bottom {
    padding: 15px;
  }
}