import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IOfferModel} from "../../models/offer/IOfferModel";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {ProductType} from "../../models/product/ProductType";
import {HttpStatus} from "../../network/status/HttpStatus";
import {IOfferProductTemplatesModel} from "../../models/offer/product/templates/IOfferProductTemplatesModel";
import {OfferProductTemplateService} from "./product/template/OfferProductTemplateService";
import {IOfferDetailsModel} from "../../models/offer/IOfferDetailsModel";
import {IOfferQuoteSubscriptionOutgoing} from "../../models/offer/quote/IOfferQuoteSubscriptionOutgoing";
import {ErrorCode} from "../../network/status/error/ErrorCode";
import {ISiteAddressModel} from "../../models/site/address/ISiteAddressModel";
import {Resources} from "../../resources/Resources";
import {IOfferPaymentSecretsModel} from "../../models/offer/payment/IOfferPaymentSecretsModel";

export class OfferService {
    public static offers: IOfferModel[] = observable([]);

    public static dispose(): void {
        this.offers = observable([]);
    }

    public static init(): void {

    }

    public static async findAll(component?: INetworkComponent): Promise<IOfferProductTemplatesModel> {
        let request = await Network.get(ProductType.PANEL, `/offer/templates`, component);
        if (request.status == HttpStatus.OK) {
            let templates: IOfferProductTemplatesModel = request.data;

            Object.keys(templates.templates).forEach(value => {
                templates.templates[value] = OfferProductTemplateService.store(templates.templates[value]);
            });

            return templates;
        }
    }


    public static async active(displayPayment: boolean, component?: INetworkComponent): Promise<IOfferDetailsModel> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.PANEL, `/offer/active/${displayPayment}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async scheduled(component?: INetworkComponent): Promise<IOfferDetailsModel> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.PANEL, `/offer/schedule`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async cancel(offerId: string, isCancel: boolean, component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.OFFER_TASK_IN_PROGRESS,
                message: Resources.t("words.taskInProgressOnCurrentOffer")
            },
            {
                errorCode: ErrorCode.OFFER_CANCELLED_EXPIRED,
                message: Resources.t("words.offerCancelledExpired")
            },
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.post(ProductType.PANEL, `/offer/cancel/${offerId}/${isCancel}`, {}, component);
        return request.status == HttpStatus.OK;
    }

    public static async getDateCancel(offerId: string, component?: INetworkComponent): Promise<number> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.PANEL, `/offer/cancel/estimateDate/${offerId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    //Récupérer le devis en cours quand tu actualises la page
    public static async quote(component?: INetworkComponent): Promise<IOfferDetailsModel> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.PANEL, `/offer/quote`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    //Créer un devis
    public static async quoteSubscription(offerSubscription: IOfferQuoteSubscriptionOutgoing, component?: INetworkComponent): Promise<IOfferDetailsModel> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.PROMOTION_CODE_INVALID,
                message: Resources.t("words.promoCodeInvalid")
            },
            {
                errorCode: ErrorCode.OFFER_ALREADY_EXISTS,
                message: Resources.t("words.youAlreadyHaveAnOngoingQuote")
            },
            {
                errorCode: ErrorCode.ACTIVE_OFFER_IS_FAILURE_TO_PAY,
                message: Resources.t("words.currentOfferPaymentDefault")
            },
            {
                errorCode: ErrorCode.OFFER_SAME_OFFER_ACTIVE,
                message: Resources.t("words.sameOfferConfiguration")
            }
        ]);

        let request = await Network.postJson(ProductType.PANEL, "/offer/quote/subscription", offerSubscription, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async quoteCancel(component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.OFFER_TASK_IN_PROGRESS,
                message: Resources.t("words.taskInProgressOnCurrentOffer")
            },
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.delete(ProductType.PANEL, `/offer/quote/cancel`, component);
        return request.status == HttpStatus.OK;
    }

    public static async quoteConfirmed(data: {
        offerId: string,
        address: ISiteAddressModel,
        advantageCode: string
    }, component?: INetworkComponent): Promise<IOfferDetailsModel> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.PROMOTION_CODE_INVALID,
                message: Resources.t("words.promoCodeInvalid")
            },
            {
                errorCode: ErrorCode.ACTIVE_OFFER_IS_FAILURE_TO_PAY,
                message: Resources.t("words.currentOfferPaymentDefault")
            },
            {
                errorCode: ErrorCode.OFFER_TASK_IN_PROGRESS,
                message: Resources.t("words.taskInProgressOnCurrentOffer")
            },
        ]);

        let request = await Network.postJson(ProductType.PANEL, "/offer/quote/confirmed", data, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async deleteCb(component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.OFFER_ACTIVE_EXISTS,
                message: Resources.t("words.cannotDeleteCardDueToActiveOffer")
            },
            {status: HttpStatus.NOT_FOUND, message: Resources.t("words.noCarError")}
        ]);

        let request = await Network.delete(ProductType.PANEL, `/offer/cb`, component);
        return request.status == HttpStatus.OK;
    }

    public static async getOfferPaymentSecrets(component?: INetworkComponent): Promise<IOfferPaymentSecretsModel> {
        Services.handleErrors(component, [
            {status: HttpStatus.BAD_REQUEST, message: Resources.t("words.cannotRetrievePaymentInfo")},
            {status: HttpStatus.NOT_FOUND, message: Resources.t("words.noBankCardRegistered")}
        ]);

        let request = await Network.get(ProductType.PANEL, `/offer/cb/paymentSecret`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    /**
     * store
     */
    public static storeAll(offers: IOfferModel[]): IOfferModel[] {
        for (let key in offers)
            offers[key] = this.store(offers[key]);

        return Services.storeAll(offers);
    }

    public static store(offer: IOfferModel): IOfferModel {
        offer = Services.store("id", this.offers, offer);
        return offer;
    }
}