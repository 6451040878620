import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-presentation.scss";
import {Network} from "../../../../../../network/Network";
import {Resources} from "../../../../../../resources/Resources";
import {NetworkVendor} from "../../../../../../network/NetworkVendor";
import {WebsiteVideoComponent} from "../video/WebsiteVideoComponent";

export class WebsitePresentationComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentation}">
                <div class="${s.left}">
                    <h2 class="${s.title}">
                        ${Resources.t("words.optimizeCustomerRelationship")}
                    </h2>
                    <p class="${s.description}">
                        ${Resources.t("words.sedestralSolutionInnovation")}
                    </p>
                    <a class="${s.startButton}" href="${Network.router.pages.getPanelUrl('signup')}" target="_blank">
                        ${Resources.t("words.begin")}
                        <i class="${s.icon}"></i>
                    </a>
                </div>
                <div class="${s.right}">
                    ${this.draw(new WebsiteVideoComponent(NetworkVendor.image("blog-video-a.mp4")))}
                </div>
            </div>
        `;
    }
}