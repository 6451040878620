import * as s from "../website-content.scss";
import {WebsiteSolutionsComponent} from "../../solutions/WebsiteSolutionsComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePresentationComponent} from "../../presentation/WebsitePresentationComponent";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {WebsiteReviewsComponent} from "../../reviews/WebsiteReviewsComponent";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";

export class WebsiteContentHomeComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationComponent())}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteSolutionsComponent())}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteReviewsComponent(Resources.t("words.whatOurClientsSay"), NetworkVendor.reviews()))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.home")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}