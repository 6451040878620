@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteHeadSubmenuContent {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 10px 20px;
  height: 100%;

  .login {
    background: var(--offer-free);
    color: var(--white);
    position: relative;
    border-radius: 10px;
    width: calc(100% - 10px);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 5px;

    @include texts.font("semi-bold");
  }

  .column {
    .title {
      padding: 0 15px !important;
      margin-bottom: 18px;
      text-align: left;
      color: var(--grey-text);
      font-size: 17px;
      font-weight: unset;

      @include texts.font("semi-bold");

      &.open {
        .arrow {
          transform: rotate(90deg);
        }
      }

      .arrow {
        transition: 0.1s;
        height: 16px;
        width: 16px;
        margin-left: auto;
        display: none;

        @include icons.ico("black-right-bold");
      }
    }

    .cases {
      padding: 0 15px !important;
      display: flex;
      flex-direction: column;
      gap: 22px;
      margin-bottom: 20px;

      .line {
        height: 1px;
        width: 100%;
        background: var(--grey-sur);
      }

      .case {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: -10px;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
        max-width: 280px;

        &.inbox, .livechat, .mail, .statistics, .helpcenter, .contacts, .serviceClient {
          visibility: visible;
        }

        &:hover {
          background: var(--grey-sur);
        }

        .capsuleContainer {
          margin-top: 4px;
          min-width: 42px;
          height: 42px;
          width: 42px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.grey {
            background: var(--grey-sur);
            border-radius: 100%;
          }

          .icon {
            height: 24px;
            width: 24px;
            background: red;

            &.writing {
              @include icons.ico("3d-ai-writing");
            }

            &.style {
              @include icons.ico("3d-style");
            }

            &.autopilot {
              @include icons.ico("3d-ai");
            }
          }
        }

        .texts {
          text-align: left;
          visibility: visible;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .caseTitle {
            color: var(--black);
            font-size: 19px;

            @include texts.font("bold");
          }

          .description {
            font-size: 13px;

            @include texts.font("medium");

            strong {
              font-weight: unset;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding: 0;
    display: block;

    .column {

      .title {
        color: var(--black);
        border-bottom: 2px dashed var(--grey-sur);
        height: 56px;
        display: flex;
        margin-bottom: unset;
        align-items: center;
        font-size: 18px;
        cursor: pointer;

        &:hover {
          background: var(--grey-sur);
        }

        .arrow {
          display: block;
        }
      }

      .cases {
        margin-top: 20px;
        display: none;
      }
    }
  }
}