import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    WebsiteContentBlogPrestashopComponent
} from "../components/content/pages/blog/WebsiteContentBlogPrestashopComponent";

export class WebsiteBlogPrestashopPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("blogPrestashop");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentBlogPrestashopComponent();
    }

    onHash(): void {

    }
}